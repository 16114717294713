import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import ReactPlayer from "react-player";
import heroImage from "../assets/blossom/blossom.png";
import problemQuestionImage from "../assets/blossom/Frame 670.png";
import researchImage from "../assets/blossom/Frame 71.png";
import earlyIterationImage from "../assets/blossom/hero-image-995cd29d.png";
import radialTreeMapImage from "../assets/blossom/Screenshot 2023-11-29 at 3.57.45 PM.png";
import fourDirectionMapImage from "../assets/blossom/Screenshot 2024-02-28 at 9.55.10 PM.png";
import sidebarDemoVideo from "../assets/blossom/Screen Recording 2024-01-22 at 12.34.59 AM.mov";
import sidebarDemoImage from "../assets/blossom/current-iteration.png";
import spatialMapImage from "../assets/blossom/search-completed.png";
import { useEffect } from "react";
import { ChevronLeft, ChevronUp } from "lucide-react";

const images = {
	problemQuestionImage,
	researchImage,
	earlyIterationImage,
	radialTreeMapImage,
	fourDirectionMapImage,
	sidebarDemoVideo,
	sidebarDemoImage,
	spatialMapImage,
};

const content1 = `

# Live Product

Blossom is a fully functional, AI-integrated web browser that connects your web & files for more efficient research. Our website is live at [blossom-app.com](https://blossom-app.com). It documents more of our features, and it has a link to join our waitlist (beta coming December 2024!). Here is a short demo of Blossom:

`;

const content2 = `

# Process

This product has been in development for over a year, and we've iterated *a lot*. As such, this page does not cover all of the design decisions we've made, but it does provide insight into our design process and our current product and vision.

## Background

Blossom was first imagined in the summer of 2023 while I was studying abroad in Berlin, which was a formative time for me. There, I met a fellow curious student — Avaye Dawadi — on the same project team for [Gallery](/gallery). I also began chatting with [Professor Michael Nitsche](https://homes.lmc.gatech.edu/~nitsche/), whose work in interaction design continues to inspire me.

At the time, I was obsessed with the trendy new web browser Arc by The Browser Company, which made me realize that the web browser doesn't have to be so boring. Together with Avaye and Professor Nitsche, we looked back at the history of browsers and began pondering. 

### The Problem

With Professor Nitsche's help, we combed over research around the decisions that led the designs of past and present web browsers, specifically around the type of browsing they were designed for. What we found opened our eyes: even the most "advanced" browsers are designed for **iterative search**, like simple Google searches, but there was an entire unexplored avenue that remains unsupported: **exploratory search**.

![Research](researchImage)

The latter is most commonly found in the behavior of casual and professional researchers, who frequently open a plethora of tabs to help accomplish an unclear end goal (usually to learn about a topic). If you've ever observed these individuals or have been one of them, you know how quickly tabs can get out of hand, leading to critical lost information and stunted productivity. Thus, we posed the question:

![The problem](problemQuestionImage)

## Early Design

### Concepts

Our first iteration focused on mapping. From studying the issues of current browsers, we realized that a significant problem with them is that they do not provide an intuitive mental map of where users are relative to their browsing history and goals. Thus, we focused on creating an actual map to orient users as they browse. We first experimented with radial tree maps, which were visually appealing and conveyed a lot of information about parent-child relationships between tabs, like so:

![Radial Tree Map](radialTreeMapImage)

###### An example of what a perfect radial tree map of a user's browsing history might look like.

After attempting to develop this map using p5.js, we quickly encountered the issues of realistic, messy browsing histories. Our radial tree only seemed to be intuitive when each parent-child relationship was equally weighted, which is an unrealistic scenario for actual users. 

![Early Iteration](earlyIterationImage)

###### A radial tree map of a demo user's browsing history.

With everything we learned from talking to users about their browsing habits and about this map design, we turned back to our research for more inspiration. Early spatial browsers, such as those designed for the first AR/VR headsets, simplified spatial mapping to just four directions: up, down, left, and right. We wondered if we could create a similar map that was easier for users to understand that still maintained the context that we liked about the radial tree map.

![Spatial Map](spatialMapImage)

###### A spatial map of a demo user's browsing history, drawing inspiration from FPS games.

![Four Direction Map](fourDirectionMapImage)

###### A simplified, four-direction map of a demo user's browsing history, where each node represents a tab.

We loved that this map was much more intuitive, especially for diving down rabbit holes. It mimicked a train map, so users could easily understand how to navigate their own browsing history. With this map design, we were ready to better integrate it into the browser.

### The Sidebar

Sidebars have been popular in browsers since the beginning of the web because they are a clean and unobstructive way to display a lot of information. But given that Blossom focuses on exploratory search, we wanted to make a more immersive experience, where the map was the primary interface of navigating your tabs. Testing this ourselves, however, we found that this was not a familiar enough experience, so we began experimenting with more immersive sidebars instead.

![Sidebar Demo](sidebarDemoVideo)

###### An extremely bouncy prototype of Blossom's expandable sidebar.

![Sidebar Demo](sidebarDemoImage)

###### A polished version of the sidebar iteration, heavily inspired by Arc.

By fitting the map into the sidebar, we had finally made an inuititive enough way to navigate your tabs with the context of parent-child relationships. But by squishing it into this small space, it was not really much of a map anymore, and we lost the sense of perspective that we liked about the four-direction map. So we retained this structure and just turned it into tree-style tabs!

## Current Design

After multiple pivots, Blossom looks and feels quite different today. From the early iterations focused on mapping, we've since carried over the most important aspect: an intuitive way to represent your tabs for exploratory search. Now, we still have the same vision of a research-focused web browser, but with two major changes:

- Connecting your web to your OS
- Integrating AI in a way that puts you in control

Here is a short demo of Blossom's current iteration:

`;

const content3 = `

## Outlook

Blossom is currently in alpha, with a general release on the horizon. We are currently working on bug fixes and quality-of-life improvements, but the core features detailed on this page are all fully implemented. This page is just a glimpse into the design process that led us here, so please feel free to reach out if you'd like to learn more!

`;

const Blossom = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="flex flex-col w-full min-h-screen items-center gap-y-8 lg:gap-y-20 p-4 lg:p-20 bg-[#FFF6ED]">
			<div className="flex flex-col lg:flex-row header w-full max-w-[1000px] bg-[#e5ab69] p-4 lg:p-20 rounded-3xl shadow-[inset_0_0_10px_4px_rgba(0,0,0,0.1)]">
				<div className="flex flex-col w-full lg:min-w-[400px] lg:max-w-[400px]">
					<Link to="/">
						<div className="flex w-12 h-12 lg:w-16 lg:h-16 bg-[#FF5C00] rounded-full absolute top-0 left-0 m-4 items-center justify-center text-black">
							<ChevronLeft size={24} />
						</div>
					</Link>
					<h1 className="text-6xl lg:text-[120px] font-medium mb-4 tracking-[-0.04em] lg:mt-0 mt-12">
						Blossom
					</h1>
					<div className="details grid grid-cols-2 w-full lg:w-[385px] gap-y-2 gap-x-4 text-sm lg:text-base">
						<p className="font-semibold text-right">Project Duration</p>
						<p>June 2023 - Present</p>
						<p className="font-semibold text-right">Role</p>
						<p>Co-Founder, Product Lead</p>
						<p className="font-semibold text-right">Team Member</p>
						<div className="flex flex-col leading-tight mt-[2px]">
							<a
								href="https://avaye.netlify.app/"
								className="hover:underline"
								target="_blank"
								rel="noopener noreferrer"
							>
								Avaye Dawadi, Co-Founder
							</a>
						</div>
						<p className="font-semibold text-right">Tools Used</p>
						<div className="flex flex-col leading-tight mt-[2px]">
							<p>Figma</p>
							<p>Electron</p>
							<p>React</p>
							<p>Tailwind CSS</p>
							<p>LLM APIs</p>
						</div>
					</div>
				</div>
				<div className="flex w-full items-center justify-center mt-8 lg:mt-0">
					<img
						src={heroImage}
						alt="hero"
						className="object-contain w-full max-w-[300px] lg:max-w-[500px] rounded-2xl lg:ml-20"
					/>
				</div>
			</div>
			<div className="body w-full pt-8 lg:pt-10 max-w-[1000px] p-4 lg:p-20 bg-[#FFF6ED] rounded-3xl shadow-[inset_0_0_10px_0_rgba(0,0,0,0.1)]">
				<Markdown
					rehypePlugins={[rehypeRaw]}
					components={{
						h1: ({ node, ...props }) => (
							<h1
								className="text-3xl lg:text-4xl font-bold my-6 lg:my-8 text-center"
								{...props}
							/>
						),
						h2: ({ node, ...props }) => (
							<h2
								className="text-2xl lg:text-3xl font-bold mb-4 lg:mb-6 mt-4"
								{...props}
							/>
						),
						h3: ({ node, ...props }) => (
							<h3
								className="text-xl lg:text-2xl font-semibold mb-2"
								{...props}
							/>
						),
						h4: ({ node, ...props }) => (
							<h4
								className="text-xl lg:text-2xl font-semibold mb-2 leading-tight"
								{...props}
							/>
						),
						h5: ({ node, ...props }) => (
							<h5 className="text-xl lg:text-2xl font-medium mb-2" {...props} />
						),
						h6: ({ node, ...props }) => (
							<h6
								className="text-xs lg:text-sm italic text-center mt-[-16px] lg:mt-[-32px] mb-6 lg:mb-8"
								{...props}
							/>
						),
						p: ({ node, ...props }) => (
							<p
								className="mb-4 leading-tight text-neutral-800 text-sm lg:text-base"
								{...props}
							/>
						),
						img: ({ node, ...props }) => {
							const imageSrc = images[props.src] || props.src;
							if (imageSrc.endsWith(".mp4") || imageSrc.endsWith(".mov")) {
								return (
									<ReactPlayer
										url={imageSrc}
										controls
										className="my-8 lg:my-12 rounded-2xl mx-auto w-full"
										width="100%"
										height="auto"
									/>
								);
							}
							return (
								<img
									src={imageSrc}
									alt={props.alt}
									className="w-full h-auto object-contain my-8 lg:my-12 max-h-[300px] lg:max-h-[500px] rounded-2xl mx-auto"
								/>
							);
						},
						a: ({ node, ...props }) => (
							<a
								className="text-[#FF5C00] hover:text-[#FF5C00] underline"
								target="_blank"
								rel="noopener noreferrer"
								{...props}
							/>
						),
						ul: ({ node, ...props }) => (
							<ul className="list-disc list-inside mb-4 ml-4" {...props} />
						),
					}}
				>
					{content1}
				</Markdown>
				<div className="aspect-video w-full">
					<ReactPlayer
						url="https://www.youtube.com/watch?v=8P1Jbu9qBmA"
						controls
						className="my-8 lg:my-12 rounded-2xl mx-auto"
						width="auto"
						height="100%"
					/>
				</div>
				<Markdown
					rehypePlugins={[rehypeRaw]}
					components={{
						h1: ({ node, ...props }) => (
							<h1
								className="text-3xl lg:text-4xl font-bold my-6 lg:my-8 text-center"
								{...props}
							/>
						),
						h2: ({ node, ...props }) => (
							<h2
								className="text-2xl lg:text-3xl font-bold mb-4 lg:mb-6 mt-4"
								{...props}
							/>
						),
						h3: ({ node, ...props }) => (
							<h3
								className="text-xl lg:text-2xl font-semibold mb-2"
								{...props}
							/>
						),
						h4: ({ node, ...props }) => (
							<h4
								className="text-xl lg:text-2xl font-semibold mb-2 leading-tight"
								{...props}
							/>
						),
						h5: ({ node, ...props }) => (
							<h5 className="text-xl lg:text-2xl font-medium mb-2" {...props} />
						),
						h6: ({ node, ...props }) => (
							<h6
								className="text-xs lg:text-sm italic text-center mt-[-16px] lg:mt-[-32px] mb-6 lg:mb-8"
								{...props}
							/>
						),
						p: ({ node, ...props }) => (
							<p
								className="mb-4 leading-tight text-neutral-800 text-sm lg:text-base"
								{...props}
							/>
						),
						img: ({ node, ...props }) => {
							const imageSrc = images[props.src] || props.src;
							if (imageSrc.endsWith(".mp4") || imageSrc.endsWith(".mov")) {
								return (
									<ReactPlayer
										url={imageSrc}
										controls
										className="my-8 lg:my-12 rounded-2xl mx-auto w-full"
										width="100%"
										height="auto"
									/>
								);
							}
							return (
								<img
									src={imageSrc}
									alt={props.alt}
									className="w-full h-auto object-contain my-8 lg:my-12 max-h-[300px] lg:max-h-[500px] rounded-2xl mx-auto"
								/>
							);
						},
						a: ({ node, ...props }) => (
							<a
								className="text-[#FF5C00] hover:text-[#FF5C00] underline"
								target="_blank"
								rel="noopener noreferrer"
								{...props}
							/>
						),
						ul: ({ node, ...props }) => (
							<ul className="list-disc list-inside mb-4 ml-4" {...props} />
						),
					}}
				>
					{content2}
				</Markdown>
				<div className="aspect-video w-full">
					<ReactPlayer
						url="https://www.youtube.com/watch?v=8P1Jbu9qBmA"
						controls
						className="my-8 lg:my-12 rounded-2xl mx-auto"
						width="auto"
						height="100%"
					/>
				</div>
				<Markdown
					rehypePlugins={[rehypeRaw]}
					components={{
						h1: ({ node, ...props }) => (
							<h1
								className="text-3xl lg:text-4xl font-bold my-6 lg:my-8 text-center"
								{...props}
							/>
						),
						h2: ({ node, ...props }) => (
							<h2
								className="text-2xl lg:text-3xl font-bold mb-4 lg:mb-6 mt-4"
								{...props}
							/>
						),
						h3: ({ node, ...props }) => (
							<h3
								className="text-xl lg:text-2xl font-semibold mb-2"
								{...props}
							/>
						),
						h4: ({ node, ...props }) => (
							<h4
								className="text-xl lg:text-2xl font-semibold mb-2 leading-tight"
								{...props}
							/>
						),
						h5: ({ node, ...props }) => (
							<h5 className="text-xl lg:text-2xl font-medium mb-2" {...props} />
						),
						h6: ({ node, ...props }) => (
							<h6
								className="text-xs lg:text-sm italic text-center mt-[-16px] lg:mt-[-32px] mb-6 lg:mb-8"
								{...props}
							/>
						),
						p: ({ node, ...props }) => (
							<p
								className="mb-4 leading-tight text-neutral-800 text-sm lg:text-base"
								{...props}
							/>
						),
						img: ({ node, ...props }) => {
							const imageSrc = images[props.src] || props.src;
							if (imageSrc.endsWith(".mp4") || imageSrc.endsWith(".mov")) {
								return (
									<ReactPlayer
										url={imageSrc}
										controls
										className="my-8 lg:my-12 rounded-2xl mx-auto w-full"
										width="100%"
										height="auto"
									/>
								);
							}
							return (
								<img
									src={imageSrc}
									alt={props.alt}
									className="w-full h-auto object-contain my-8 lg:my-12 max-h-[300px] lg:max-h-[500px] rounded-2xl mx-auto"
								/>
							);
						},
						a: ({ node, ...props }) => (
							<a
								className="text-[#FF5C00] hover:text-[#FF5C00] underline"
								target="_blank"
								rel="noopener noreferrer"
								{...props}
							/>
						),
						ul: ({ node, ...props }) => (
							<ul className="list-disc list-inside mb-4 ml-4" {...props} />
						),
					}}
				>
					{content3}
				</Markdown>
			</div>
			<div className="w-full flex justify-end mt-8">
				<div
					className="fixed bottom-4 right-4 w-12 h-12 lg:w-16 lg:h-16 bg-[#FF5C00] rounded-full flex items-center justify-center text-black cursor-pointer"
					onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
				>
					<ChevronUp size={24} />
				</div>
			</div>
		</div>
	);
};

export default Blossom;
