import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import ReactPlayer from "react-player";
import aboutImage from "../assets/home-images/about.png";
import { useEffect } from "react";
import { ChevronLeft, ChevronUp } from "lucide-react";
const images = {};

const content = `

Hi there! I appreciate you taking a look at my portfolio.
<br />
<br />
These days, I'm taking classes at Georgia Tech in all sorts of
design and computer science disciplines. In my free time, I'm creating a
<a href="/blossom">
	web browser for research.
</a>

Some random things I enjoy:
<ul>
	<li><a href="https://www.figma.com/blog/the-linear-method-opinionated-software/" target="_blank" rel="noopener noreferrer">Opinionated software</a></li>
	<li>992.1 Targa</li>
	<li>Iced flat whites</li>
</ul>

I would love to get in touch! You can email me
<a href="mailto:arjunjdewan@gmail.com">here</a>, or connect with me on
<a href="https://www.linkedin.com/in/arjunjdewan/">LinkedIn</a>.

I look forward to hearing from you!
`;

const About = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="flex flex-col w-full min-h-screen items-center p-4 lg:p-20 gap-y-8 lg:gap-y-20 bg-[#09260B] text-[#F4ECD7]">
			<div className="flex flex-col lg:flex-row header w-full max-w-[1000px] p-4 lg:p-20 bg-[#09260B] rounded-b-[50px]">
				<div className="flex flex-col w-full lg:min-w-[400px] lg:max-w-[400px]">
					<Link to="/">
						<div className="flex w-12 h-12 lg:w-16 lg:h-16 bg-[#FF5C00] rounded-full absolute top-0 left-0 m-4 items-center justify-center text-black">
							<ChevronLeft size={24} />
						</div>
					</Link>
					<h1 className="text-6xl lg:text-[120px] font-medium mb-4 tracking-tight lg:mt-0 mt-12">
						About
					</h1>
				</div>
				<div className="flex w-full items-center justify-center mt-8 lg:mt-0">
					<img
						src={aboutImage}
						alt="hero"
						className="w-full object-contain my-4 lg:my-12 max-h-[300px] lg:max-h-[500px] rounded-2xl mx-auto"
					/>
				</div>
			</div>
			<div className="body w-full pt-8 lg:pt-10 max-w-[1000px] p-4 lg:p-20 rounded-[50px]">
				<Markdown
					rehypePlugins={[rehypeRaw]}
					components={{
						h1: ({ node, ...props }) => (
							<h1 className="text-4xl font-bold my-8 text-center" {...props} />
						),
						h2: ({ node, ...props }) => (
							<h2 className="text-3xl font-bold mb-6 mt-4" {...props} />
						),
						h3: ({ node, ...props }) => (
							<h3 className="text-2xl font-semibold mb-2" {...props} />
						),
						h4: ({ node, ...props }) => (
							<h4
								className="text-2xl font-semibold mb-2 leading-tight"
								{...props}
							/>
						),
						h5: ({ node, ...props }) => (
							<h5 className="text-2xl font-medium mb-2" {...props} />
						),
						h6: ({ node, ...props }) => (
							<h6
								className="text-sm italic text-center mt-[-32px] mb-8"
								{...props}
							/>
						),
						p: ({ node, ...props }) => (
							<p
								className="text-xl mb-4 leading-tight text-[#F4ECD7]"
								{...props}
							/>
						),
						a: ({ node, ...props }) => (
							<a
								className="text-[#FF5C00] underline"
								target="_blank"
								rel="noopener noreferrer"
								{...props}
							/>
						),
						ul: ({ node, ...props }) => (
							<ul
								className="list-disc list-inside mb-4 ml-4 text-xl"
								{...props}
							/>
						),
						img: ({ node, ...props }) => {
							const imageSrc = images[props.src] || props.src;
							if (imageSrc.endsWith(".mp4")) {
								return (
									<ReactPlayer
										url={imageSrc}
										controls
										className="my-12 rounded-2xl mx-auto"
									/>
								);
							}
							return (
								<img
									src={imageSrc}
									alt={props.alt}
									className="h-full object-contain my-12 max-h-[500px] rounded-2xl mx-auto"
								/>
							);
						},
					}}
				>
					{content}
				</Markdown>
			</div>
			<div className="w-full flex justify-end mt-8">
				<div
					className="fixed bottom-4 right-4 w-12 h-12 lg:w-16 lg:h-16 bg-[#FF5C00] rounded-full flex items-center justify-center text-black cursor-pointer"
					onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
				>
					<ChevronUp size={24} />
				</div>
			</div>
		</div>
	);
};

export default About;
