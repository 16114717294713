import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import { ChevronLeft, ChevronUp } from "lucide-react";
import ReactPlayer from "react-player";
import abstract from "../assets/photography/abstract.jpg";
import alpina from "../assets/photography/alpina.jpg";
import alpinaBlue from "../assets/photography/alpinaBlue.jpg";
import cloak from "../assets/photography/cloak.jpg";
import corvette from "../assets/photography/corvette.jpg";
import countach from "../assets/photography/countach.jpg";
import csl from "../assets/photography/csl.jpg";
import delorean1 from "../assets/photography/delorean1.jpg";
import delorean2 from "../assets/photography/delorean2.jpg";
import delorean3 from "../assets/photography/delorean3.jpg";
import f40 from "../assets/photography/f40.jpg";
import ferrari from "../assets/photography/ferrari.jpg";
import garage1 from "../assets/photography/garage1.jpg";
import garage2 from "../assets/photography/garage2.jpg";
import gt3 from "../assets/photography/gt3.jpg";
import lancia from "../assets/photography/lancia.jpg";
import leather from "../assets/photography/leather.jpg";
import nissanZ from "../assets/photography/nissanZ.jpg";
import orange from "../assets/photography/orange.jpg";
import porscheDetail from "../assets/photography/porscheDetail.jpg";
import project7 from "../assets/photography/project7.jpg";
import steering from "../assets/photography/steering.jpg";
import wheels from "../assets/photography/wheels.jpg";
import { useEffect } from "react";

const images = {};

const content = `

My love for taking photos first started when I watched my Dad tinker with his camera on family vacations throughout my childhood. As I developed a love for cars around my tenth birthday, I started attending car shows and eventually brought my camera to them. This page is a collection of my favorite shots, mostly of automobiles.

`;

const Photography = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="flex flex-col w-full min-h-screen items-center p-4 lg:p-20 bg-[#010101] text-[#dd362a]">
			<div className="flex flex-col lg:flex-row header w-full max-w-[1000px] p-4 lg:p-20 bg-[#010101]">
				<div className="flex flex-col w-full lg:min-w-[400px] lg:max-w-[400px]">
					<Link to="/">
						<div className="flex w-12 h-12 lg:w-16 lg:h-16 bg-[#FF5C00] rounded-full absolute top-0 left-0 m-4 items-center justify-center text-black">
							<ChevronLeft size={24} />
						</div>
					</Link>
					<h1 className="text-[52px] lg:text-[120px] font-medium mb-4 lg:tracking-tight tracking-[-0.04em] lg:mt-0 mt-12">
						Photography
					</h1>
				</div>
			</div>
			<div className="body w-full lg:pt-10 max-w-[1000px] p-4 lg:p-20 rounded-[50px]">
				<Markdown
					rehypePlugins={[rehypeRaw]}
					components={{
						h1: ({ node, ...props }) => (
							<h1 className="text-4xl font-bold my-8 text-center" {...props} />
						),
						h2: ({ node, ...props }) => (
							<h2 className="text-3xl font-bold mb-6 mt-4" {...props} />
						),
						h3: ({ node, ...props }) => (
							<h3 className="text-2xl font-semibold mb-2" {...props} />
						),
						h4: ({ node, ...props }) => (
							<h4
								className="text-2xl font-semibold mb-2 leading-tight"
								{...props}
							/>
						),
						h5: ({ node, ...props }) => (
							<h5 className="text-2xl font-medium mb-2" {...props} />
						),
						h6: ({ node, ...props }) => (
							<h6
								className="text-sm italic text-center mt-[-32px] mb-8"
								{...props}
							/>
						),
						p: ({ node, ...props }) => (
							<p
								className="text-xl mb-4 leading-tight text-[#dd362a] font-medium"
								{...props}
							/>
						),
						a: ({ node, ...props }) => (
							<a
								className="text-[#FF5C00] hover:text-[#FF5C00] underline"
								target="_blank"
								rel="noopener noreferrer"
								{...props}
							/>
						),
						ul: ({ node, ...props }) => (
							<ul className="list-disc list-inside mb-4 ml-4" {...props} />
						),
						img: ({ node, ...props }) => {
							const imageSrc = images[props.src] || props.src;
							if (imageSrc.endsWith(".mp4")) {
								return (
									<ReactPlayer
										url={imageSrc}
										controls
										className="my-12 rounded-2xl mx-auto"
									/>
								);
							}
							return (
								<img
									src={imageSrc}
									alt={props.alt}
									className="h-full object-contain my-12 max-h-[500px] rounded-2xl mx-auto"
								/>
							);
						},
					}}
				>
					{content}
				</Markdown>
				<div className="flex flex-col w-full mt-12 lg:mt-20 gap-y-8 lg:gap-y-12">
					<img
						src={garage1}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={garage2}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={lancia}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={cloak}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={csl}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={ferrari}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={project7}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={wheels}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={alpina}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={f40}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={abstract}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={orange}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={nissanZ}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={delorean1}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={delorean2}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={delorean3}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={countach}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={corvette}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={gt3}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={porscheDetail}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={leather}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={alpinaBlue}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
					<img
						src={steering}
						alt="noise"
						className="w-full object-contain border-white border-8"
					/>
				</div>
			</div>
			<div className="w-full flex justify-end mt-8">
				<div
					className="fixed bottom-4 right-4 w-12 h-12 lg:w-16 lg:h-16 bg-[#FF5C00] rounded-full flex items-center justify-center text-black cursor-pointer"
					onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
				>
					<ChevronUp size={24} />
				</div>
			</div>
		</div>
	);
};

export default Photography;
